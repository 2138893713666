import { GetStaticProps, NextPage } from "next";
import { getDynamicPage } from "@/services/prepr/queries/getDynamicPage";
import { revalidationInterval } from "@/utilities/revalidationInterval";
import { getSlug } from "@/utilities/getSlug";
import { DynamicPageProps } from "@/components/pages/DynamicPage/DynamicPage";
import { DynamicPage as DynamicPageComponent } from "@/components/pages/DynamicPage/DynamicPage";

const DynamicPage: NextPage<DynamicPageProps> = ({ dynamicPage }) => (
  <DynamicPageComponent dynamicPage={dynamicPage} />
);

const logGetDynamicPage = async (slug: string) => {
  // const throwIfNotExists = async (slug: string) => {
  //   if (!(await getDynamicPageExists(slug))) throw "page-does-not-exist";
  // };

  // const t0 = Date.now();
  try {
    const [result] = await Promise.all([
      getDynamicPage(slug),
      // throwing here causes the code to not wait for slow `getDynamicPage` result
      // throwIfNotExists(slug),
    ]);
    return result;
  } catch (e) {
    if (e === "page-does-not-exist") return null;

    // eslint-disable-next-line no-console
    console.log(`logGetDynamicPage error: ${e}`);
    throw e;
  } finally {
    // uncomment to debug prepr load
    // const elapsed = Date.now() - t0;
    // console.log(`logGetDynamicPage "${slug}" took ${elapsed}ms`);
  }
};

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const slug = getSlug(params?.slug);
  const dynamicPage = await logGetDynamicPage(`/${slug}`);
  return dynamicPage?.content
    ? { props: { dynamicPage }, revalidate: revalidationInterval }
    : { notFound: true, revalidate: revalidationInterval };
};

export async function getStaticPaths() {
  return {
    paths: [],
    fallback: "blocking",
  };
}

export default DynamicPage;
