import Head from "next/head";
import DynamicContentBlock from "@/components/blocks/DynamicContentBlock";
import { Path } from "@/utilities/types";
import { GetDynamicPageQuery } from "@/services/prepr/queries/getDynamicPage.generated";

type DynamicPage = NonNullable<Path<GetDynamicPageQuery, ["DynamicPage"]>>;

export interface DynamicPageProps {
  dynamicPage: DynamicPage;
}
export const DynamicPage: React.FC<DynamicPageProps> = ({ dynamicPage }) => {
  return (
    <>
      <Head>
        {dynamicPage?.title && <title>{dynamicPage?.title}</title>}
        {dynamicPage?.summary && <meta name="description" content={dynamicPage?.summary} />}
      </Head>
      <div className="tablet:mb-20 mb-12">
        {dynamicPage?.content?.map((item, i) => {
          return (
            <DynamicContentBlock
              key={i}
              id={i}
              item={item}
              prevTypename={dynamicPage.content?.[i - 1]?.__typename}
            />
          );
        })}
      </div>
    </>
  );
};
